'use-client';

import useScreen from 'hooks/useScreen';
import PropTypes from 'prop-types';

const MobileAndTabletOnlyLegacy = ({ children, fallback = null }) => {
  const { isMobileOrTablet } = useScreen();

  return isMobileOrTablet ? children : fallback;
};

MobileAndTabletOnlyLegacy.propTypes = {
  children: PropTypes.node,
};

export default MobileAndTabletOnlyLegacy;
